import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useSpring, animated, config } from 'react-spring'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import arrow from '../images/left-chevron.svg'

const Wrapper = styled.div`
  display: flex;
  position: relative;
`

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${props => props.theme.maxWidths.general};
  padding: 2rem 1.0875rem 4rem 1.0875rem;
  color: ${props => props.theme.colors.secondary};
`

const Back = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  img[data-info='back'] {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 1rem 0 0;
  }
`

const Avatar = styled.div`
  height: 3rem;
  width: 3rem;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;

  img {
    border-radius: 50%;
    height: auto;
    width: 100%;
  }
`

const Name = styled(animated.h4)`
  margin: 0 0 0 1rem;
  color: ${props => props.theme.colors.color};
`

const Details = styled.div`
  width: 100%;
  margin-top: 6rem;
  text-align: center;

  h1 {
    color: ${props => props.theme.colors.color};
  }
`

const Text = styled.div`
  max-width: 750px;
  margin: 4rem auto 2rem auto;
  color: white;
`

// TODO put dimension, matiere etc in a grid to have the bold labels and the content align properly
const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 500px;
  justify-content: center;
`

const ProjectHeader = ({ avatar, name, description, origine, dimensions, matiere, text }) => {
  const contentProps = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Wrapper>
      <Content>
        <Back to="/">
          <img src={arrow} data-info="back" alt="Back to home" aria-label="Back to home" />
          <Avatar>
            <img src={avatar} alt={name} />
          </Avatar>
          <Name>{name}</Name>
        </Back>
        <Details>
          <animated.div style={contentProps}>
            <div>{description}</div>
            {origine && (
              <div style={{ marginTop: '1rem' }}>
                <span style={{ fontWeight: 'bold' }}>Origin</span>: {origine}
              </div>
            )}
            {dimensions && (
              <div>
                <span style={{ fontWeight: 'bold' }}>Dimensions</span>: {dimensions}
              </div>
            )}
            {matiere && (
              <div>
                <span style={{ fontWeight: 'bold' }}>Matiere</span>: {matiere}
              </div>
            )}
            <div>
              <span style={{ fontWeight: 'bold' }}>Contact</span> :{' '}
              <a href="mailto:rickcummings1@yahoo.fr">rickcummings1@yahoo.fr</a>
            </div>
            {text && (
              <Text>
                <MDXRenderer>{text}</MDXRenderer>
              </Text>
            )}
          </animated.div>
        </Details>
      </Content>
    </Wrapper>
  )
}

export default ProjectHeader

ProjectHeader.propTypes = {
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  origine: PropTypes.string,
  dimensions: PropTypes.string,
  matiere: PropTypes.string,
  text: PropTypes.string.isRequired,
}

ProjectHeader.defaultProps = {
  origine: '',
  dimensions: '',
  matiere: '',
}
