import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSpring, animated, config } from 'react-spring'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const CARD_COLORS = [
  {
    bg: '#CC3300',
    color: '#FFFFFF',
  },
  {
    bg: '#34AB52',
    color: '#FFFFFF',
  },
  {
    bg: '#FFCF00',
    color: '#1F1F1F',
  },
  {
    bg: '#4D87F2',
    color: '#1F1F1F',
  },
  {
    bg: '#000000',
    color: '#FFCF00',
  },
  {
    bg: '#CC3300',
    color: '#FFFFFF',
  },
]

const CardItem = styled(Link)`
  width: 330px;
  height: 290px;
  background-color: ${props => props.bg};
  color: ${props => props.color};

  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.color};
  transition: all 0.3s ease-in-out;
  margin-bottom: 1rem;
`

const Cover = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Card = ({ index, path, cover, delay }) => {
  const springProps = useSpring({
    config: config.slow,
    delay: 80 * delay,
    from: { opacity: 0, transform: 'translate3d(0, 30px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
  })

  const cardColor = CARD_COLORS[(index || 0) % CARD_COLORS.length]

  return (
    <animated.div style={springProps}>
      <CardItem to={path} bg={cardColor.bg} color={cardColor.color}>
        <Cover>
          <Img fluid={cover} style={{ width: '100%', height: '100%' }} imgStyle={{ objectFit: 'contain' }} />
        </Cover>
      </CardItem>
    </animated.div>
  )
}

export default Card

Card.propTypes = {
  index: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  cover: PropTypes.object.isRequired,
  delay: PropTypes.number.isRequired,
}
