import React from 'react'
import styled from 'styled-components'

const Content = styled.footer`
  color: ${props => props.theme.colors.secondary};
  text-align: center;
  font-size: 0.9rem;
  padding: 3rem 1rem;
  background: ${props => props.theme.colors.bg};
`

const Footer = () => <Content>&copy; {new Date().getFullYear()} Resources in chaos kathmandu</Content>

export default Footer
